<template>
<span>
    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_foto" persistent max-width="1000px">
                <v-card>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12 class="foto">
                                    <img v-if="bnnr_ft_foto" :src="`${base_url}upload/banner_photo/${bnnr_ft_foto}?${uuid}`" class="foto-ampliada" />
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_foto = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Imagens Banner</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="primary" dark class="mb-1" @click="atualizarDados">
                <v-icon dark class="mdi mdi-sync"></v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'bannerphotos.add', params: {bnnr_id: bnnr_id}}">
                <v-icon class="mdi mdi-plus"></v-icon>Adicionar Nova imagem
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadBannerPhotos" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.bnnr_ft_foto`]="{ item }">
                <td width="15%" v-if="item.bnnr_ft_foto"><img :src="`${base_url}upload/banner_photo/${item.bnnr_ft_foto}?${uuid}`" class="avatar" @click.prevent="ampliarFoto(item.bnnr_ft_foto)"></td>
                <td width="15%" v-if="!item.bnnr_ft_foto"><img :src="`${base_url}upload/logo.png`" class="avatar"></td>
            </template>

            <template v-slot:[`item.bnnr_ft_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.bnnr_ft_status" @change="onChangeEventHandler(item.bnnr_ft_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editBannerPhotos(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteBannerPhotos(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="ampliarFoto(item.bnnr_ft_foto)" v-on="on">
                            <v-icon dark class="mdi mdi-image-search"></v-icon>
                        </v-btn>
                    </template>
                    <span>Ampliar Imagem</span>
                </v-tooltip>
            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    uuid
} from "vue-uuid";
import {
    URL_BASE
} from "../../../config/configs";

export default {
    name: "BannerPhotoComponent",
    created() {
        this.$store.dispatch("loadBannerPhotosByBanner", this.bnnr_id);
    },
    computed: {
        loadBannerPhotos() {
            if (this.$store.state.bannerphotos.imagens.data !== null)
                return this.$store.state.bannerphotos.imagens;
        }
    },
    props: {
        bnnr_id: {
            require: true
        }
    },
    data() {
        return {
            bnnr_ft_foto: null,
            dialog_foto: false,
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    value: -1
                }
            ],
            headers: [{
                    text: "Imagem",
                    align: "left",
                    sortable: false,
                    value: "bnnr_ft_foto"
                },
                {
                    text: "Banner",
                    value: "banner.bnnr_nome"
                },
                {
                    text: "Link",
                    value: "bnnr_ft_link"
                },
                {
                    text: "Alvo",
                    value: "bnnr_ft_link_target"
                },
                {
                    text: "Descrição",
                    value: "bnnr_ft_descricao"
                },
                {
                    text: "Ordem",
                    value: "bnnr_ft_ordem",
                    align: "center"
                },
                {
                    text: "Status",
                    value: "bnnr_ft_status"
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            banner: [],
            base_url: URL_BASE,
            uuid: uuid.v1()
        };
    },
    methods: {
        atualizarDados() {
            this.$store.dispatch("loadBannerPhotosByBanner", this.bnnr_id);
        },
        ampliarFoto(foto) {
            this.bnnr_ft_foto = foto;
            this.dialog_foto = true;
        },
        editBannerPhotos(item) {
            this.$router.push({
                name: "bannerphotos.edit",
                params: {
                    bnnr_ft_id: item.bnnr_ft_id
                }
            });
        },
        deleteBannerPhotos(item) {
            this.$swal({
                title: "Você tem certeza?",
                text: "Você não poderá reverter isso",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#D32F2F",
                cancelButtonColor: "#1976D2",
                confirmButtonText: "Sim, Exclua!"
            }).then(result => {
                if (result.value) {
                    this.$store
                        .dispatch("destroyBannerPhoto", item.bnnr_ft_id)
                        .then(response => {
                            if (response) {
                                this.atualizarDados();
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: "Opssss!",
                                    text: "Erro ao excluir dados",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: "Opssss!",
                                text: "Algo errado aconteceu!",
                                showConfirmButton: true,
                                timer: 6000
                            });
                        });
                }
            });
        },
        onChangeEventHandler(id, value) {
            this.$store
                .dispatch("updateBannerPhotoStatus", {
                    bnnr_ft_id: id,
                    status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar status",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        }
    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}

.foto-ampliada {
    max-width: 100%;
}

.foto {
    display: flex;
    justify-content: center;
}
</style>
